import Home from "./Home";
import { Routes, Route,BrowserRouter } from "react-router-dom"
import BlogPage from "./templates/BlogPage";
import { BlogIntro } from "./templates/BlogIntro";
import About from "./templates/About";
import ShowPlan from "./templates/ShowPlan";
import FaqComponent from "./templates/Faq";
import Plans from "./templates/Plans";
import CorporateWellness from "./templates/CorporateWellness";
import ContactNew from "./templates/ContactNew";
import PopUp from "./templates/PopUp";
import Survey from "./templates/Survey";
import { AppProvider } from "./templates/Context";
function App() {
  return (<AppProvider>
    <div className="App">
      
      <BrowserRouter>
      {/* <PopUp/> */}
      <Survey/>
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="/blog" element={ <BlogPage/> } />
        <Route path='/health-reads'element={<BlogIntro/>}/>
        <Route path='/show-plans' element={<ShowPlan/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/faq' element={<FaqComponent/>}/>
        <Route path='/plans' element={<Plans/>}/>
        <Route path='/corporate-wellness' element={<CorporateWellness/>}/>
        <Route path='/contact' element={<ContactNew/>}/>
        <Route path='/survey' element={<Survey/>}/>



      </Routes>
      </BrowserRouter>
   
    </div>   </AppProvider>
  );
}

export default App;
