import React, { useState, useEffect, useContext } from 'react';
import { motion } from 'framer-motion';
const questions = [
  "How much weight are you looking to lose substantially?",
  "How many meals do you eat per day?",
  "Let's talk about nutrition. How often do you eat carb-dense foods?",
  "Are you currently following a specific dietary pattern?",
  "Be honest, what's your average night like?",
  "What's your lifestyle like?",
  "What sex were you assigned at birth?",
  "What is your daily water intake?",
  "What is your age?",
  "What's your height? (in cms)",
  "What's your weight? (in kgs)",
  "What's your target weight (in kgs)",
  "Is there anything specific motivating you to lose weight right now?",
  "Sounds exciting! When is your event?(enter dd/mm/yyyy)",
"How would you rate your current  health and nutrition habits?",
"Which of the following best describes your typical lunch?",
"What’s a typical day look like for you?",
"What is your body type?",
"Do you relate to the following statement? Eating is one of the ways I usually deal with my emotions",
"Do you relate to the following statement? After a week or two of eating healthier or exercising, I fall back to my old routine.",
"Do you relate to the following statement? People around me often make it hard for me to stick to healthier habits.",
"Do you relate to the following statement? Multitasking while eating has become habitual for me (like eating while working or scrolling on my phone).",
"Do you relate to the following statement? Even if I’m already full, I usually clear my plate.",
"Do you relate to the following statement? My unhealthy decisions often lead me to make more unhealthy decisions. I feel like a failure because I can’t commit to a healthier lifestyle",
];
; // Your questions array
const answerOptions = [
  ["1-2kgs", "2-5kgs", "5-10kgs", "10-20kgs", "20-30kgs"],
  ["1", "2", "3", "4+"],
  ["Very often", "Sometimes", "Rarely", "Not at all"],
  ["No", "Vegetarian", "Fully plant-based", "Pescatarian", "Lactose-free", "Gluten-free", "Keto", "Others"],
  ["Minimal rest (less than 5 hours)", "I get some shut-eye (5-6 hours)", "I sleep long and well (7-8 hours)", "I'm a sleep superhero (more than 8 hours)"],
  ["Sedentary", "Moderately active", "Very active"],
  ["Female", "Male"],
  [],
  [],
  [],
  [],
  [],
  ["Vacation", "Wedding", "Sports Event", "Summer", "School reunion", "Family Gatering", "Birthday Party", "No event-just ready to look and feel my best!", "Other"],
  [],
  ["My diet isn't great and my activity levels need improving", "My diet and activity goals could be improved", "I try to practice healthy lifestyle habits"],
  ["I tend to eat sandwiches or wraps", "I tend to eat soups or salads", "I tend to eat fast food", "Other"],
  ["I almost always eat three or fewer meals per day","I almost always eat at least three meals, plus several snacks a day","It depends — sometimes less than three meals, sometimes more"],
  ["Rounded", "Triangle", "Rectangle", "Inverted Triangle", "Hourglass"],
  ["1", "2", "3","4", "5"],
  ["1", "2", "3","4", "5"],
  ["1", "2", "3","4", "5"],
  ["1", "2", "3","4", "5"],
  ["1", "2", "3","4", "5"],
  ["1", "2", "3","4", "5"],
]; // Your answerOptions array
const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

const questionVariants = {
  hidden: { x: '-100%' },
  visible: { x: 0, transition: { type: 'spring', stiffness: 120 } },
};

const buttonVariants = {
  hover: { scale: 1.05 },
};
const inputVariants = {
  hidden: { opacity: 0, x: '-50%' },
  visible: { opacity: 1, x: '0%', transition: { delay: 0.2, duration: 0.5 } },
};

function Survey() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  // const [waterInput, setWaterInput] = useState('');
  const [value, setValue] = useState('');
  const [showSurvey, setShowSurvey] = useState(false);
   // Added state to control survey visibility
  //  const [render, setRender]=useState(true)
console.log(showSurvey);
useEffect(() => {
  const hasCompletedForm = localStorage.getItem('hasCompletedForm');

  if (hasCompletedForm) {
    // User has completed the form, hide the survey
    setShowSurvey(false);
  } else {
    // User hasn't completed the form, show the survey
    setShowSurvey(true);
  }
}, []);

const handleFormSubmission = () => {
  localStorage.setItem('hasCompletedForm', 'true');
  setShowSurvey(false);
  // localStorage.removeItem('hasCompletedForm');
  console.log('Form submitted:', answers);
};
  // const handleWaterInput = (event) => {
  //   setWaterInput(event.target.value);
  // };
 
  const handleAnswer = (answer, question) => {
    setAnswers([...answers, question + " : " + answer]);
    setCurrentQuestion(currentQuestion + 1);
    setValue('');
  };

  const handleInputValue = (event) => {
    setValue(event.target.value);
  };

  const progressBarWidth = ((currentQuestion + 1) / questions.length) * 100;

  return (
    <>{showSurvey && 
    <motion.div
      className="fixed z-10 min-h-screen w-screen bg-white/20 flex justify-center items-center"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    > 
      <motion.div className="max-w-md p-8 bg-white rounded-lg shadow-md" variants={questionVariants}>
        <div className='font-mono text-sm text-center'>Fill out this quick Survey, we will help you acheive your Goals Faster!</div>
        <div className="mb-6 relative">
          <div className="h-2 bg-green-300 rounded-full">
            <motion.div
              className="h-full bg-green-500 rounded-full"
              style={{ width: `${progressBarWidth}%` }}
            ></motion.div>
          </div>
          <motion.div
            className="absolute top-0 left-0 h-2 bg-green-400 rounded-full"
            style={{ width: `${progressBarWidth}%` }}
            initial={{ width: 0 }}
            animate={{ width: `${progressBarWidth}%` }}
          ></motion.div>
        </div>
        {currentQuestion < questions.length ? (
          <motion.div variants={questionVariants}>
            <h1 className="font-semibold font-mono text-[15px] text-center mb-4">{questions[currentQuestion]}</h1>
            {answerOptions[currentQuestion].length === 0 ? (
              <div className="mb-4">
                <motion.input
                  type="text"
                  className="block w-full py-2 px-4 rounded-md border border-gray-300 focus:ring focus:ring-green-300 focus:border-green-300"
                  value={value}
                  onChange={(e) => handleInputValue(e)}
                  variants={inputVariants}
                  initial="hidden"
                  animate="visible"
                />
                <motion.button
                  className='bg-green-300 px-10 py-3 rounded-xl font-mono text-white mt-3 text-[12px]'
                  onClick={() => handleAnswer(value, questions[currentQuestion])}
                  whileHover="hover"
                  variants={buttonVariants}
                >
                  Continue
                </motion.button>
              </div>
            ) : (
              answerOptions[currentQuestion].map((option, index) => (
                <motion.button
                  key={index}
                  onClick={() => handleAnswer(option, questions[currentQuestion])}
                  className="block w-full py-2 px-4 mb-2 text-center bg-green-300 text-white font-mono text-[12px] rounded-md hover:bg-green-400 transition duration-300"
                  whileHover="hover"
                  variants={buttonVariants}
                >
                  {option}
                </motion.button>
              ))
            )}
          </motion.div>
        ) : (
          <motion.div variants={questionVariants}>
            <h1 className="font-mono font-semibold mb-4 text-[15px]">Thank you for completing the survey!</h1>
            <motion.button
              onClick={handleFormSubmission}
              className="block w-full py-2 px-4 bg-green-300 text-white rounded-md hover:bg-green-400 transition duration-300"
              whileHover="hover"
              variants={buttonVariants}
            >
              Submit
            </motion.button>
          </motion.div>
        )}
      </motion.div>
    </motion.div>
  }</>);
}

export default Survey;
