import React, { useState, useEffect } from "react";
import { Typography } from "@material-tailwind/react";
import img from '../images/1.png'
import Header from "./Header";
import Footer from "./Footer";

export default function ContactNew() {
  const [data, setData] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/corporateWellness`);
      const jsonData = await response.json();
      setData(jsonData[0]);
      console.log(jsonData[0]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Perform submission logic here
    const formData = {
      name: name,
      email: email,
      phoneNumber: contactNumber,
      message: message
    };
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/contacts`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
          });
    
          if (response.ok) {
            console.log('Data created successfully');
            setSuccessMessage(true)
          } else {
            console.error('Error creating data:', response.statusText);
          }
        } catch (error) {
          console.error('Error creating data:', error);
        }
    setName("");
    setEmail("");
    setContactNumber("");
    setMessage("");

  };


  const handleSnackbarClose = () => {
    setSuccessMessage(false);
  };

  return (
    <div>
      <div
        className="border-b overflow-hidden bg-contain bg-no-repeat bg-[#D9F49E]"
        id="about"
        style={{ backgroundImage: `url(${img})` }}
      >
        <Header color="black" />
        <div className="container my-24 mx-auto md:px-6 h-auto">
          <div className="w-4/5 ml-auto text-center text-green-900 text-3xl font-ubu">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-4xl lg:text-6xl text-transparent bg-clip-text bg-gradient-to-br from-green-900 to-red-700 border-b border-green-900"
            >
              Contact Us
            </Typography>
          </div>

          <div className="my-20">
            <form onSubmit={handleSubmit} className="ml-auto w-[75%] mt-2 flex flex-col">
              <label htmlFor="name" className="text-green-900 font-medium mb-1">
                Enter your Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring focus:ring-green-500"
              />

              <label htmlFor="email" className="text-green-900 font-medium mb-1">
                Enter your Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring focus:ring-green-500"
              />

              <label htmlFor="contactNumber" className="text-green-900 font-medium mb-1">
                Enter your Contact Number
              </label>
              <input
                type="tel"
                id="contactNumber"
                value={contactNumber}
                onChange={(e) => setContactNumber(e.target.value)}
                required
                className="px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring focus:ring-green-500"
              />

              <label htmlFor="message" className="text-green-900 font-medium mb-1">
                Enter your Message
              </label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
                rows={4}
                className="px-4 py-2 border border-gray-300 rounded-md mb-4 focus:outline-none focus:ring focus:ring-green-500"
              ></textarea>

              <button
                type="submit"
                className="px-6 py-2 bg-green-500/60 text-white rounded-md hover:bg-green-600 focus:outline-none focus:bg-green-600"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <Footer />

      {successMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg">
            <Typography variant="h5" color="green" className="mb-2">
              Data submitted successfully!
            </Typography>
            <button
              color="green"
              onClick={handleSnackbarClose}
              ripple="light"
              className="ml-auto"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
