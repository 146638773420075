import React, { createContext, useContext, useState } from 'react';

// Create a new context
export const AppContext = createContext();

// Create a custom hook to access the context
// export const useAppContext = () => useContext(AppContext);

// Create a context provider component
export function AppProvider({ children }) {
  const [showSurvey1, setShowSurvey1] = useState(true);

  return (
    <AppContext.Provider value={{ showSurvey1, setShowSurvey1 }}>
      {children}
    </AppContext.Provider>
  );
}