import React, { useEffect, useRef, useState } from 'react'
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from 'react-router-dom';
export default function BlogDisplay({id}) {
  const[data1,setData1]=useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/blogs`);
      const jsonData = await response.json();
      console.log(jsonData)
      setData1(jsonData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

    console.log(id)
    const divsContainerRef = useRef(null);
  const [showDivs, setShowDivs] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setShowDivs(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { rootMargin: "0px", threshold: 0.1 } // Adjust the threshold as needed
    );

    if (divsContainerRef.current) {
      observer.observe(divsContainerRef.current);
    }

    return () => {
      if (divsContainerRef.current) {
        observer.unobserve(divsContainerRef.current);
      }
    };
  }, []);
    const staggerDelay = 0.4
const navigate=useNavigate()

    const moveToBlog = (data)=>
    {
      console.log(data)
      navigate('/blog',{state:data})

    }
  return (
    <div className='w-[800px] h-auto flex font-poppins items-center justify-center' ref={divsContainerRef}>
        <div className='h-auto w-[750px] flex flex-col '>
            <text className='text-[#0f1935] text-[24px] font-poppins font-bold leading-[32px]'>Latest</text>
            <div className=" flex flex-wrap justify-center ">
            <AnimatePresence>
                
            {
                showDivs &&
                data1.map((data)=>(
                  <span>
                    {data.categoryId==id &&
                    <motion.div className='h-[352px] w-[350px] flex justify-around items-center'
                    initial={{ opacity: 0, z: -100, y:-100 }}
                    animate={{ opacity: 1, z: 0, y:0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ ease: "easeOut", duration: 1 }}
                    >
                      <button onClick={()=>moveToBlog(data)}>
                        <div className='h-[280] w-[350] flex flex-col justify-around items-center p-1 hover:bg-white/50 rounded-[5px] hover:scale-105 transition-all ease-in-out duration-300'>
                        <div className='h-[240px] w-[300px] bg-cover bg-no-repeat' style={{backgroundImage: `url(${data.image})`}}></div>
                        <div className='h-[12px] w-[153px] flex justify-between text-green-500'>
                        <text className='text-[13px] uppercase text-[#f5556e]'>{data.category}</text>
                        </div>
                        <div className='h-[60px] w-[320px] text-[15px] leading-[30px] mt-5'>{data.heading}</div>
                        </div>
                        </button>
                    </motion.div>
                    }
                     {id===0 &&
                    <motion.div className='h-[352px] w-[350px] flex justify-around items-center'
                    initial={{ opacity: 0, z: -100, y:-100 }}
                    animate={{ opacity: 1, z: 0, y:0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ delay: staggerDelay }}
                    >
                      <button onClick={()=>moveToBlog(data)}>
                        <div className='h-[280] w-[350] flex flex-col justify-around items-center p-1 hover:bg-white/50 rounded-[5px] hover:scale-105 transition-all ease-in-out duration-300'>
                        <div className='h-[240px] w-[300px] bg-cover bg-no-repeat' style={{backgroundImage: `url(${data.image})`}}></div>
                        <div className='h-[12px] w-[153px] flex justify-between text-green-500'>
                        <text className='text-[13px] uppercase text-[#f5556e]'>{data.category}</text>
                        {/* <text className='text-[13px]' >{data.time} mins read</text> */}
                        </div>
                        <div className='h-[60px] w-[320px] text-[15px] leading-[30px] mt-5'>{data.heading}</div>
                        </div>
                        </button>
                    </motion.div>
                    }
                  </span>
                   
                    
                
                ))
            }
            </AnimatePresence>
            </div>

        </div>
      
    </div>
  )
}
