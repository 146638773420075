import React, {useState,useEffect} from "react";
import { Typography } from "@material-tailwind/react";
import img from '../images/1.png'
import Header from "./Header";
import Footer from "./Footer";
export default function FaqComponent() {
  const [data,setData]=useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/feedback`);
      const jsonData = await response.json();
      setData(jsonData);
      console.log(jsonData)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
    return (
      <>
        <div
        className=" border-b overflow-hidden bg-contain bg-no-repeat bg-[#D9F49E]"
        id="about"
        style={{ backgroundImage: `url(${img})` }}
      >
        <Header color="black"/>
        <div
          className="container my-24 mx-auto md:px-6 h-auto" >
          <div className=" w-4/5 ml-auto text-center text-green-900 text-3xl font-ubu">
            <Typography
              variant="h1"
              color="white"
              className="mb-4 text-3xl md:text-4xl lg:text-6xl text-transparent bg-clip-text bg-gradient-to-br from-green-900 to-red-700 border-b border-green-900"
            >
              Frequently Asked Questions
            </Typography>
           
          </div>
           {data.map((one)=>
            <div className="my-20">
                 <div className=" w-[78%] ml-auto text-center text-green-900 text-xl font-ubu">
                    {one.ques}
                 </div>
                 <div className="ml-auto w-[75%] font-mono mt-2">
                  {one.ans}
                 </div>
            </div>
            )}
         
        </div>
      </div>
      <Footer/>
      </>
    )
}