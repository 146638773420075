import React, { useEffect, useState } from "react";

const WhatsAppButton = () => {
    const [data,setData]=useState();
    useEffect(()=>
    {
        fetchData()
    },[])
    
    const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BASE_URL}/footer`);
          const jsonData = await response.json();
          setData(jsonData[0]);
          console.log(jsonData[0]);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
  const handleWhatsAppClick = () => {
    // Replace the "1234567890" with the desired WhatsApp number
    const phoneNumber = `${data.phoneNumber}`;

    // Create the WhatsApp URL with the phone number
    const url = `https://wa.me/${phoneNumber}`;

    // Open the WhatsApp link in a new tab
    window.open(url, "_blank");
  };

  return (
    <button
      onClick={handleWhatsAppClick}
      className="bg-green-500 hover:bg-green-600 text-white px-4 mx-3 py-2 rounded"
    >
      Chat on WhatsApp
    </button>
  );
};

export default WhatsAppButton;
